import  styled  from 'styled-components';

export const Skills = styled.div`
    padding: 1rem 2rem;
    margin-bottom: 1.5rem;
    margin-right: 1.5rem;
    font-size: 1.6rem;
    background: rgba(153,153,153,.2);
    border-radius: 5px;
    font-weight: 600;
    color: #666;
`
